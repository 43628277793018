<template>
  <div>
    <!-- 遮罩层显示加载中 -->
    <!-- <div id="appLoading">
      <div class="lmask">
        <div class="loading roate"></div>
      </div>
    </div> -->
    <div id="apps">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  // 父组件向子孙组件传递数据
  provide() {
    return {
      loading: this.loading,
      loaded: this.loaded,
    };
  },

  methods: {
    loading() {
      document.getElementById("app").style.display = "block";
      document.getElementById("appLoading").style.display = "block";
    },
    loaded() {
      document.getElementById("app").style.display = "block";
      document.getElementById("appLoading").style.display = "none";
    },
  },
};
</script>

<style>
@import "./assets/css/mask.css";
</style>
