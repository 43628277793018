import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
// import QS from 'qs'

//导入字体图标
import './assets/fonts/iconfont.css'
import "./assets/font1/iconfont.css"

//导入全局样式表
import './assets/css/global.css'


// import axios from 'axios'

// 导入封装的axios
import Axios from './network/axios'

// 导入打印插件
import Print from "vue-print-nb";

import QRcode from "@/components/QRcode";
// axios.defaults.baseURL = "http://localhost:5500/"
// axios.interceptors.request.use(config => {
//   // console.log(config);
//   config.headers.Authorization = window.sessionStorage.getItem('token')
//   return config;
// });


// Vue.prototype.$axios = axios

// 使用封装的axios
Vue.prototype.$axios = Axios

Vue.use(ElementUI);
Vue.use(Print);
Vue.use(QRcode);

Vue.config.productionTip = false
// Vue.prototype.$setTitle = function (title) {
//   document.title = title
// }
// this.$setTitle('test')

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
